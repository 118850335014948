import { Time } from '@sapphire/duration';

export enum ProjectsQueryCacheTime {
  DETAILS = Time.Hour * 8,
  LIST = Time.Hour * 8,
  PRODUCTION_REVIEWS = Time.Hour * 4,
  SELECT_LIST = Time.Hour * 4,
}

export enum ActivitiesCacheTime {
  DETAILS = Time.Hour * 12,
  LIST = Time.Hour * 8,
  SELECT_LIST = Time.Hour * 8,
}

export enum ProjectAreasCacheTime {
  LIST = Time.Hour * 4,
  SELECT_LIST = Time.Hour * 4,
}

export enum StatusesCacheTime {
  LIST = Time.Hour * 12,
  SELECT_LIST = Time.Hour * 12,
}

export enum StatusTypesCacheTime {
  LIST = Time.Hour * 12,
  SELECT_LIST = Time.Hour * 12,
}

export enum MeasurementUnitsCacheTime {
  CONVERSIONS = Time.Hour * 4,
  LIST = Time.Hour * 4,
  SELECT_LIST = Time.Hour * 4,
}

export enum MeasurementsCacheTime {
  LIST = Time.Hour * 4,
  SELECT_LIST = Time.Hour * 4,
}

export enum LaborTypesCacheTime {
  LIST = Time.Hour * 4,
}

export enum LaborsQueryCacheTime {
  LIST = Time.Hour * 4,
  SELECT_LIST = Time.Hour * 4,
}

export enum MachineryCacheTime {
  LIST = Time.Hour * 4,
  SELECT_LIST = Time.Hour * 4,
}

export enum MachineryFamilyCacheTime {
  LIST = Time.Hour * 4,
  SELECT_LIST = Time.Hour * 4,
}

export enum MachineryTypeCacheTime {
  LIST = Time.Hour * 4,
  SELECT_LIST = Time.Hour * 4,
}

export enum MachineryAdjustmentCacheTime {
  DETAILS = Time.Hour * 4,
  LIST = Time.Hour * 4,
}

export enum MaterialsCacheTime {
  LIST = Time.Hour * 4,
  SELECT_LIST = Time.Hour * 4,
}

export enum SchudulesCacheTime {
  DETAILS = Time.Hour * 4,
  LIST = Time.Hour * 4,
  SELECT_LIST = Time.Hour * 4,
}

export enum MaterialTypesCacheTime {
  LIST = Time.Hour * 4,
  SELECT_LIST = Time.Hour * 4,
}

export enum ProjectCategoriesCacheTime {
  LIST = Time.Hour * 4,
  LIST_SELECT = Time.Hour * 1,
}

export enum CountryCacheTime {
  DETAILS = Time.Hour * 12,
  LIST = Time.Hour * 12,
  SELECT_LIST = Time.Hour * 12,
}

export enum CityCacheTime {
  LIST = Time.Hour * 12,
}

export enum DepartmentCacheTime {
  DETAILS = Time.Hour * 12,
  LIST = Time.Hour * 12,
  SELECT_LIST = Time.Hour * 12,
}

export enum GendersCacheTime {
  LIST = Time.Hour * 12,
  SELECT_LIST = Time.Hour * 12,
}

export enum MeasurementUnitsTypesCacheTime {
  LIST = Time.Hour * 4,
}

export enum RolesCacheTime {
  CLAIMS = Time.Hour * 4,
  DETAILS = Time.Hour * 12,
  LIST = Time.Hour * 12,
  SELECT_LIST = Time.Hour * 12,
  USERS = Time.Hour * 12,
}

export enum UsersCacheTime {
  CURRENT = Time.Hour * 4,
  DETAILS = Time.Hour * 4,
  LIST = Time.Hour * 12,
  SELECT_LIST = Time.Hour * 12,
}

export enum ReportsCacheTime {
  PROJECT_CUMULATIVE = Time.Hour * 4,
  PROJECT_CUMULATIVE_COST = Time.Hour * 4,
}

export enum ActivityTypeCacheTime {
  LIST = Time.Hour * 2,
  SELECT_LIST = Time.Hour * 2,
}

export enum MachineryPriceHistoryCacheTime {
  LIST = Time.Hour * 2,
  SELECT_LIST = Time.Hour * 2,
}

export enum LaborPriceHistoryCacheTime {
  LIST = Time.Hour * 2,
  SELECT_LIST = Time.Hour * 2,
}

export enum MaterialPriceHistoryCacheTime {
  LIST = Time.Hour * 2,
  SELECT_LIST = Time.Hour * 2,
}

export enum ActivityChecksCacheTime {
  DETAILS = Time.Minute * 10,
  LIST = Time.Hour * 2,
  SELECT_LIST = Time.Hour * 2,
}

export enum LaborAdjustmentCacheTime {
  LIST = Time.Hour,
}
export enum MaterialAdjustmentCacheTime {
  LIST = Time.Hour,
}

export enum IncomeTrackCacheTime {
  DETAILS = Time.Hour * 1,
  LIST = Time.Hour * 1,
}

export enum KPISCacheTime {
  ACTIVITIES_EXPECTED_UNEXPECTED = Time.Minute * 5,
  ACTIVITIES_STATUSES = Time.Minute * 5,
  ACTIVITIES_WITH_ADDITIONAL = Time.Minute * 5,
  ACTIVITIES_WITH_SAVINGS = Time.Minute * 5,
  CUMULATIVE_COMPARATIVE = Time.Minute * 5,
  INCIDENCE_OF_ACTIVITIES = Time.Minute * 5,
  INDIRECT_COST_COMPARATIVE = Time.Minute * 5,
  PROFABILITY_TRACKING_INCOME = Time.Minute * 5,
  PROFABILITY_TRACKING_UTILIY = Time.Minute * 5,
  PROJECT_COMPARATIVE = Time.Minute * 5,
}

export enum PlanningDashboardCacheTime {
  CONSTRAINTSSUMMARY = Time.Minute * 5,
  CRITICALPHYSICALPROGRESS = Time.Minute * 5,
  FINANCIALPROGRESS = Time.Minute * 5,
  TIMETABLEINDICATORS = Time.Minute * 5,
}
