'use client';
import { create } from 'zustand';

import { ProjectScheduleType } from '@/lib/types/api/schedules/project-schedules';
import { UserAuthenticateAssignProject } from '@/lib/types/api/user/user';

export interface SelectedProjectForStore {
  closingDay: null | number;
  currencySymbol: string;
  endDate: string;
  hasSchedule: boolean;
  id: string;
  minorToolPerformance: number;
  name: string;
  projectSchedules: SelectedProjectSchedulesList[];
  sellPrice: number;
  startDate: string;
  status: UserAuthenticateAssignProject['status'];
}

type SelectedProjectStore = {
  selectedProject: SelectedProjectForStore | null;
  setSelectedProject: (project: SelectedProjectForStore | null) => void;
};

export const useSelectedProjectStore = create<SelectedProjectStore>()((set, get) => ({
  selectedProject: null,
  setSelectedProject: (project) => set({ selectedProject: project }),
}));

export interface SelectedProjectSchedulesList {
  id: string;
  type: ProjectScheduleType;
}
